@keyframes scale_animate {
    0% {
      transform: scale(1)
    }
    20% {
      transform: scale(0.8)
    }
    70% {
      transform: scale(1.6)
    }
    100% {
      transform: scale(1)
    }
  }
  
  .dashboard-wrapper {
    position: relative;
    .row-mid {
      padding: 6px 10px
    }
    .row-top, .row-bottom {
      padding: 10px 10px;
    }
    .wrapper {
      background-color: #fff;
      border: 6px solid #f0f2f5;
      &:hover {
        transition: all .5s ease;
      }
    }
    .chart-wrapper {
      background-color: #fff;
      padding: 10px;
      border: 6px solid #f0f2f5;
      border-radius: 8px;
      &:hover {
        box-shadow: 0 0 12px 2px rgba(189, 189, 189, .75);
        transition: all .5s ease-in-out;
        border-color: white;
      }
    }
    .block-wrapper {
      height: 200px;
      line-height: 200px;
      background-color: #fff;
      padding-left: 25%;
      padding-right: 25%;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
  
      &:hover {
        box-shadow: 0 0 12px 2px rgba(189, 189, 189, .75);
        transition: all .5s ease-in-out;
      }
  
      .star {
  
        color: #42b983
      }
  
      .eye {
  
        color: #1890ff
      }
  
      .save {
  
        color: lightcoral
      }
  
      .message {
  
        color: deeppink
      }
  
      .anticon {
  
        flex: 1;
        font-size: 30px
  
      }
  
      .anticon:hover {
        animation: scale_animate .5s;
        animation-timing-function: cubic-bezier(0.1, -0.6, 0.2, 0);
      }
      span {
        flex: 1;
        font-weight: bold;
        font-size: larger;
        font-family: "Comic Sans MS"
      }
    }
  }
  