:root {
  --white-color: #ffffff;
  --corpo-color: #437085;
  --corpo-color-hover: #0A4966;
  --white_b-color: #dfe1e5;
  --blue-color: #00aab5;
  --dark-blue: #001529;
  --box-shadow: #F0f0f0;
  --blackh1-color: #2d2f30;
  --grey-color: #f4f5f7;
  --home-color: #383b41;
  --footer-color: #232322;
  --grey-ind-color: #c1c7d0;
  --grey-dark: #818181;
  --grey-disabled: #c3c3c3;
  --blue-hover: #2462a6;
  --error-color: #e66565;
  --border-color: #a5adbb;
}

.adminContainer {
  min-height: 100vh;
}

.authContainer {
  min-height: 100vh;
  background-image: url(../img/bg.png);
}
.gwh1 {
  color: var(--corpo-color);
  font-weight: 700;
  font-size: 22px !important;
}
.site-content {
  height: 100vh;   
}
.login-form {
  width: 100%;
 
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
  border-radius: 5px;
  background-color: var(--corpo-color);
  border-color: var(--corpo-color);
  &:hover{
    background-color: var(--corpo-color-hover);
    border-color: var(--corpo-color-hover);
  }
  &:active{
    background-color: var(--corpo-color-hover);
    border-color: var(--corpo-color-hover);
  }
  &:focus{
    background-color: var(--corpo-color-hover);
    border-color: var(--corpo-color-hover);
  }
}
.form-padding {
  padding: 40px;
  background-color: #ffffff;
  border-radius: 5px;
}

.logoHeader {
  width: 120px;
}
.logo {
  height: 32px;
  margin-left: 16px;
}

.site-layout .site-layout-background {
  background: var(--blue-color) !important;
}

.siderClass {
  background-color:var(--dark-blue) !important;
}
.mainHeader{ 
  height: 48px;
  background-color: #001529 !important;
box-shadow: inset 0px -1px 0px #F0F0F0;
}

.rightContainer {
  display: flex;
  align-items: center;
}

.footerbg {
  margin-left: auto; 
  margin-right: 30px;
}

.ql-editor {
  height: 200px !important;
}

.dot {
  margin-left: 14px;
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
.isactive {
  background-color:green !important;
}
.isdeactive {
  background-color:red !important;
}


.horasTot {
  float: right;
  font-weight: 700;
}
.hora {
  width: 50px !important;
}