#sidebar-trigger {
  color: #fff !important;
}
.layout-page {
   height: 100%;
   min-height: 100vh !important;
  &-header {
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 9;
    background-color: var(--dark-blue) !important;
    &-main {
      padding: 0 15px;
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .logo {
      width: 170px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9;
      img {
        width: 150px;
      }
    }
  }
  &-sider {
    background-color: #fff !important;
    box-sizing: border-box;
    border-right: 1px solid #f0f0f0;
    margin-bottom: 10px;
  }
  &-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    > :nth-child(1) .ant-tabs-bar {
      padding: 6px 0 0;
      background: #fff;
    }

    > :nth-child(2) {
      flex: auto;
      overflow-y: auto;
      overflow-x: hidden;
      margin: 12px;
      box-sizing: border-box;
      background-color: #ffffff;
      .ant-form,
      .ant-table-wrapper {
        border: 6px solid #fff;
        box-sizing: border-box;
        padding: 6px;
      }

      .ant-form {
        margin-bottom: 12px;
      }
      .innerText {
        background-color: #fff;
        padding: 24px;
        border-radius: 2px;
        display: block;
        line-height: 32px;
        font-size: 16px;
      }
    }
  }
  &-footer {
    background-color: #ffffff !important;
    text-align: center;
    padding: 14px 20px;
    font-size: 12px;
  }
  .actions {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    > * {
      margin-left: 30px;
      height: 100%;
      display: flex;
      align-items: center;
      .notice {
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 22px;
        height: 22px;
        cursor: pointer;
      }
    }
  }
  .user-action {
    cursor: pointer;
  }
  .user-avator {
    margin-right: 8px;
    width: 40px;
    height: 40px;
  }
}

.layout-page-sider-menu {
  border-right: none !important;
}
.ant-menu-inline-collapsed {
  width: 79px !important;
}

.notice-description {
  font-size: 12px;
  &-datetime {
    margin-top: 4px;
    line-height: 1.5;
  }
}

.notice-title {
  display: flex;
  justify-content: space-between;
}

.tagsView-extra {
  height: 100%;
  width: 50px;
  cursor: pointer;
  display: block;
  line-height: 40px;
  text-align: center;
}

.themeSwitch {
  position: fixed;
  right: 32px;
  bottom: 102px;
  cursor: pointer;
  > span {
    display: block;
    text-align: center;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    width: 44px;
    height: 44px;
    line-height: 44px;
    font-size: 22px;
    z-index: 10001;
  }
}

.theme-color-content {
  display: flex;
  .theme-color-block {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    color: #fff;
    font-weight: 700;
    text-align: center;
    border-radius: 2px;
    cursor: pointer;
    border-radius: 2px;
    &:last-child {
      margin-right: 0;
    }
  }
}